import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import './assets/style/contactPopup.scss';
import DialogActions from '@material-ui/core/DialogActions';
import { Formik, Field, ErrorMessage, Form } from 'formik';
//import { getArrangements } from 'data/master/actions';
import CustomizedButton from 'shared/components/customizedButton';
import * as Yup from 'yup';
import "./assets/style/placeHold.scss";
import CustomTimeSelect from "./CustomTimeSelect.js";
import CustomSelect from "./CustomSelect";
import { Datepicker } from "react-formik-ui";
import moment from "moment";
import CustomisedRadio from "shared/components/CustomisedRadio.js";

const styles = theme => ({
  root: {
    margin: '20px !important',
    overflow: 'scroll',
  },
  paper: {
    margin: '20px'
  },
  label: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
      fontWeight: '500',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.46px',
      color: 'black'
    }
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      borderRadius: '6px',
      border: 'solid 0px black',
    }
  }
});

const validationSchema = Yup.object().shape({
  eventName: Yup.string("*Event Name must be a string")
    .required('*Required'),
  startDate: Yup.string("*Start Date must be a string")
    .required('*Required'),
  // endDate: Yup.string("*End Date must be a string")
  //   .required('*Required'),
  startTime: Yup.string("*Start Time must be a string")
    .required('*Required'),
  endTime: Yup.string("*End Time must be a string")
    .required('*Required'),
  setUp: Yup.string()
    .required("*Required"),
    takeDown: Yup.string("*Tear down time must be a string")
    .required('*Required'),
  noOfPeople: Yup.string("*# of people must be a string")
    .required("*Required"),
  seatingType: Yup.string("*Seating Type must be a string")
    .required('*Required'),
  isFlexible: Yup.string("*Flexible on your date must be a string")
    .required('*Required'),
  otherVenues: Yup.string("*Considering other venues must be a string")
    .required('*Required')
    .max(50, "Should not be more than 50 characters"),
  message: Yup.string("*Message must be a string")
    .required('*Required')
    .max(385, "Message should not be more than 385 characters"),

});

const LoginSchema = Yup.object().shape({
  message: Yup.string()
    .required('*Required')
    .max(385, "Message should not be more than 385 characters"),
});


const DialogContent = withStyles(theme => ({
  root: {
  },
}))(MuiDialogContent);

let seatingType=[]
class contactPopup extends React.Component {
  // constructor(props, context) {
  //   super(props, context);
  // }

  state = {
    open: this.props.infoPopup,
    showForm: false,
    input: false,
    eventsPresent: false,
    eventsSelected: false,
    eventsChecked: false,
    buttonDisabled: true,
    radioSelectedValue: '',
    errorMessage: '',
    showOptionSelect:false,
    radioSelectedValue: '',
    EventFormData:{},
    followDateContact:this.props.followDateContact?this.props.followDateContact:'',
    //seatingType:[]
  };

 /* UNSAFE_componentWillMount() {
    this.props.getArrangements().then(response => {
      seatingType = [];
      response.map(function(data, idx) {
        let obj = {};
        obj.value = data.id;
        obj.name = data.name;
        seatingType.push(obj);
        return seatingType;
      });
      this.setState({ seatingType: seatingType });    
    })
  }*/

  UNSAFE_componentWillReceiveProps(nextProps) {

  }

  handleClose = () => {
    this.setState({ open: false });
    this.props.infoPopupOpen()
    this.props.handleClose();
  };

  handleClickOpen = () => {
    this.setState({ open: true, galleryOpened: true });
  };
  handleRadioClick = event => {
    this.setState({ buttonDisabled: false });
    this.setState({ radioSelectedValue: event.target.value });

    // get all checkboxes that has className checkBox
    var options = document.getElementsByClassName('option');

    // check if any of it is checked
    for (var i = 0; i < options.length; i++) {
      if (options[i].getElementsByTagName('input')[0].checked) {
        options[i].getElementsByTagName('p')[0].setAttribute('class', 'option-text blue');
      } else {
        options[i].getElementsByTagName('p')[0].setAttribute('class', 'option-text grey');
      }
    }
  }

  handleSubmitMessage = (values, { setSubmitting }) => {


    if (values.message.includes('<', '>', '{', '}') !== true) {
      this.setState({ errorMessage: "" });
      this.setState({ open: false, showForm: false, eventsChecked: false });
      this.props.contactClick(values.message);
      this.props.infoPopupOpen()
  

    }
    else {
      this.setState({ open: true, showForm: true, eventsChecked: true });

      this.setState({ errorMessage: "Do not include <,>,{,} in your message." });

    }

    // this.setState({ open: false, showForm: false, eventsChecked: false });


  };


  handleSubmit = (values, { setSubmitting }) => {

    const spaceId = this.props.match && this.props.match.params && this.props.match.params.spaceId
    let widget = '';
    if (localStorage.getItem('widget')) {
      widget = localStorage.getItem('widget');
    }
    const formData = {
      widget: widget,
      space_id: spaceId,
      eventName: values.eventName,
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: values.endDate?moment(values.endDate).format("YYYY-MM-DD"):"",
      startTime: values.startTime,
      endTime: values.endTime,
      setUpTime: values.setUp,
      tearDownTime: values.takeDown,
      noOfPeople: values.noOfPeople,
      seatingType: values.seatingType,
      isFlexible: values.isFlexible,
      otherVenues: values.otherVenues,
      message: values.message
    }
    this.state.EventFormData=Object.assign({}, formData)
    if (values.message.includes('<', '>', '{', '}') !== true) {
      this.setState({ errorMessage: "" });
     // this.setState({ open: false, showForm: false, eventsChecked: false });
      // this.props.contactClick(formData);     
      //this.props.handleClose(); 
      this.setState({showOptionSelect:true})    

    }
    else {
      this.setState({ errorMessage: "Do not include <,>,{,} in your message." });
      this.setState({ open: true, showForm: true, eventsChecked: true });

    }

    // this.setState({ open: false, showForm: false, eventsChecked: false });


  };
  handleChange = (e) => {
    this.setState({ input: e.target.value });
  };

  handleSaveClick = () => {
    this.setState(
      prevState => ({ showForm: false, eventsPresent: true })
    );
  }

  handleSaveToEventClick = () => {
    this.setState(
      prevState => ({ eventsPresent: false, eventsSelected: true })
    );
  };

  handleRadioClick = event => {
    this.setState({ buttonDisabled: false });
    this.setState({ radioSelectedValue: event.target.value });

    // get all checkboxes that has className checkBox
    var options = document.getElementsByClassName('option');

    // check if any of it is checked
    for (var i = 0; i < options.length; i++) {
      if (options[i].getElementsByTagName('input')[0].checked) {
        options[i].getElementsByTagName('p')[0].setAttribute('class', 'option-text blue');
      } else {
        options[i].getElementsByTagName('p')[0].setAttribute('class', 'option-text grey');
      }
    }
  }

  onSelectedDate = (setFieldValue) => {
    setFieldValue('endDate', '')
  }


  handleCheckboxClick = () => {
    var showButton = false;

    // get all checkboxes that has className checkBox
    var cont = document.getElementsByClassName("checkBox");
    // var selectedOption = document.getElementsByClassName("")

    // check if any of it is checked
    for (var i = 0; i < cont.length; i++) {
      if (cont[i].checked) {
        showButton = true;
      }
    }

    if (showButton) {
      this.setState({ eventsChecked: true });
    } else {
      this.setState({ eventsChecked: false });
    }
  };
  eventSubmit= () =>{
    this.state.EventFormData=Object.assign({}, this.state.EventFormData, {readyOption : this.state.radioSelectedValue})
    this.props.contactClick(this.state.EventFormData);
    this.setState({ open: false, showForm: false, eventsChecked: false ,showOptionSelect:false,radioSelectedValue:''});
    this.props.handleClose();
    this.props.infoPopupOpen()
  }

  render() {
    const isFlexible = [
      { id: 1, name: 'Yes' },
      { id: 0, name: 'No' }
    ]
    let initialValues = {
      eventName: '',
      startDate: this.props.selectedDate?this.props.selectedDate:'',
      endDate: '',
      startTime: '',
      endTime: '',
      setUp: '',
      takeDown: '',
      noOfPeople: '',
      seatingType: '',
      isFlexible: '',
      otherVenues: '',
      message: ''
    };

    const setUpArray = [
      {
        id: "1 hr",
        name: "1 hr"
      },
      {
        id: "1 hr",
        name: "2 hr"
      },
      {
        id: "1 hr",
        name: "3 hr"
      },
      {
        id: "1 hr",
        name: "4 hr"
      },
      {
        id: "1 hr",
        name: "5 hr"
      },
      {
        id: "1 hr",
        name: "6 hr"
      },
      {
        id: "1 hr",
        name: "7 hr"
      },
      {
        id: "1 hr",
        name: "8 hr"
      },
      {
        id: "1 hr",
        name: "9 hr"
      },
      {
        id: "1 hr",
        name: "10 hr"
      },
      {
        id: "1 hr",
        name: "11 hr"
      },
      {
        id: "1 hr",
        name: "12 hr"
      },
      {
        id: "1 hr",
        name: "24 hr"
      },
      {
        id: "1 hr",
        name: "48 hr"
      },
      {
        id: "1 hr",
        name: "72 hr"
      }
    ];
    const { classes } = this.props;
    // const eventsPresent = this.state.eventsPresent;
    return (
      <div>
        {/*this.props.follow === true ?
          <Button variant="outlined" onClick={this.handleClickOpen} classes={{
            label: classes.label,
            root: classes.button
          }}
            style={{ background: 'linear-gradient(to bottom, #f1435e, #ee2738)' }}

          >
            {this.props.label}
          </Button>
          :
          <Button variant="outlined" onClick={this.handleClickOpen} classes={{
            label: classes.label,
            root: classes.button
          }}

          >
            {this.props.label}
          </Button>
        */}
        <Dialog className="contact_modal_container contact_popup"
          aria-labelledby="customized-dialog-title"
          open={this.state.open}>

          <DialogActions className="dialogActions">
            <span className="material-icons close closeButton" onClick={this.handleClose}>close</span>
          </DialogActions>
          <DialogContent className="dialog-content">
            <Grid container className="modalBox_content">
              <Grid item xs={12} className="main_place_hold_section tell-us-about-your-event">
              {parseInt(this.props.calendarEnabled) === 0 ?(
                  this.state.showOptionSelect==false?
                  <Grid>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={this.handleSubmit}
                    >

                      {({ isSubmitting, values, handleBlur, handleChange, setFieldValue }) => (<Form>
                        <div className="form">
                          <div className="header">
                            <div className="header-text">Tell us about your event</div>
                            <div className="align-send-button button-left">
                              <Button
                                variant="outline-danger"
                                className="white-button-small"
                                type="submit"
                              >
                                Send
                        </Button>
                            </div>
                          </div>
                          <div className="form-content contact-form">
                            <div className="field-div big">
                              <div className="input-label">Event Name</div>
                              <div className="input-field-section">

                                <Field
                                  className="input-field dark-black"
                                  type="text"
                                  name="eventName"
                                />
                                <div className="error">
                                  <ErrorMessage name="eventName" />
                                </div>
                              </div>
                            </div>
                            <div className="field-div small">
                              <div className="input-label end-date-sm">
                                Start Date Range
                          </div>
                              <div className="input-field-section date-picker">
                                <Datepicker
                                  onSelect={() => this.onSelectedDate(setFieldValue)}
                                  onKeyDown={e => e.preventDefault()}
                                  minDate={this.props.currentDate}
                                  className="datepiker color-blue"
                                  name="startDate"
                                  dateFormat="MM/dd/yyyy"
                                  placeholder="Start Date"
                                // disabled={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false}
                                   readOnly={this.state.followDateContact == ''?(this.props.selectedDate != '' ? true : false):false}


                                />
                              </div>
                            </div>
                            <div className="field-div small float-right">
                              <div className="input-label end-date-sm">
                                End Date Range (Optional)
                             </div>
                              <div className="input-field-section date-picker">
                                <Datepicker
                                  onKeyDown={e => e.preventDefault()}
                                  className="datepiker color-blue"
                                  minDate={moment(
                                    values.startDate
                                  ).toDate()}
                                  name="endDate"
                                  dateFormat="MM/dd/yyyy"
                                  placeholder="End Date"
                                  maxDate={moment(values.startDate)
                                    .add(15, "days")
                                    .toDate()}
                                // disabled={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false}
                                />

                              </div>
                            </div>

                            <div className="field-div small">
                              <div className="input-label sm-pd-start">Start Time</div>
                              <div className="input-field-section">
                                <CustomTimeSelect
                                  placeholder="Start Time"
                                  styleClass="browseVenue"
                                  name="startTime"
                                  value={values.startTime}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                // disable={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false}

                                />
                              </div>
                            </div>

                            <div className="field-div small float-right">
                              <div className="input-label sm-pd-end">End Time</div>
                              <div className="input-field-section">
                                <CustomTimeSelect
                                  placeholder="End Time"
                                  styleClass="browseVenue"
                                  name="endTime"
                                  value={values.endTime}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                // disable={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false}

                                />
                                <div className="error">
                                  {this.state.timeError}
                                </div>
                              </div>
                            </div>
                            <div className="field-div small">
                              <div className="input-label sm-pd-start">Requested Set-up Time</div>
                              <div className="input-field-section select-down-icon">
                                {/* <CustomTimeSelect
                                placeholder="Select"
                                styleClass="browseVenue"
                                name="setUpTime"
                                value={values.setUpTime}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              // disable={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false}

                              /> */}
                                <CustomSelect
                                  className="select-field lighter-black"
                                  id="setUp"
                                  setUpArray={setUpArray}
                                  name="setUp"
                                  placeholder="Select"
                                  color="#62717a"
                                  width="100%"

                                />
                     
                                <div className="error">
                                  <ErrorMessage name="setUp" />
                                </div>
                              </div>
                            </div>

                            <div className="field-div small float-right">
                              <div className="input-label sm-pd-end">Requested Tear Down Time</div>
                              <div className="input-field-section select-down-icon">
                                {/* <CustomTimeSelect
                                placeholder="Select"
                                styleClass="browseVenue"
                                name="tearDownTime"
                                value={values.tearDownTime}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              // disable={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false}

                              /> */}
                                <CustomSelect
                                  className="select-field lighter-black"
                                  id="takeDown"
                                  setUpArray={setUpArray}
                                  name="takeDown"
                                  placeholder="Select"
                                  color="#62717a"
                                  width="100%"
                                />
                                <div className="error">
                                  <ErrorMessage name="takeDown" />
                                </div>
                              </div>
                            </div>
                            <div className="field-div big">
                              <div className="input-label sm-pd-venue"># of People</div>
                              <div className="input-field-section">
                                <Field
                                  className="input-field dark-black"
                                  type="text"
                                  name="noOfPeople"
                                  placeholder="Ex-100-300"
                                // disabled={this.state.hold_value !== '0 Holds' && this.state.hold_value !== '' ? true : false}
                                />

                                <div
                                  className="error"
                                >
                                  <ErrorMessage name="noOfPeople" />
                                </div>
                              </div>
                            </div>
                            <div className="field-div big">
                              <div className="input-label sm-pd-venue">Seating Type</div>
                              <div className="input-field-section select-down-icon">
                                <CustomSelect
                                  id="seatingType"
                                  name="seatingType"
                                  color="#62717a"
                                  width="100%"
                                  data={this.props.seatingData}
                                  placeholder="Select"
                                //  disable={this.state.hold_value!=='0 Holds' && this.state.hold_value!==''?true:false}
                                />

                                <div
                                  className="error"
                                >
                                  <ErrorMessage name="seatingType" />
                                </div>
                              </div>
                            </div>
                            <div className="field-div big">
                              <div className="input-label">Flexible on your date?</div>
                              <div className="input-field-section select-down-icon">

                                <CustomSelect
                                  id="isFlexible"
                                  name="isFlexible"
                                  color="#62717a"
                                  width="100%"
                                  data={isFlexible}
                                  placeholder="Select"
                                //  disable={this.state.hold_value!=='0 Holds' && this.state.hold_value!==''?true:false}
                                />
                                <div className="error">
                                  <ErrorMessage name="isFlexible" />
                                </div>
                              </div>
                            </div>
                            <div className="field-div big">
                              <div className="input-label">Considering other venues?</div>
                              <div className="input-field-section">
                              <CustomSelect
                                  id="otherVenues"
                                  name="otherVenues"
                                  color="#62717a"
                                  width="100%"
                                  data={isFlexible}
                                  placeholder="Select"
                                //  disable={this.state.hold_value!=='0 Holds' && this.state.hold_value!==''?true:false}
                                />
                                {/* <Field
                                  className="input-field dark-black"
                                  type="text"
                                  name="otherVenues"
                                /> */}
                                <div className="error">
                                  <ErrorMessage name="otherVenues" />
                                </div>
                              </div>
                            </div>
                            <div className="field-div big text-area">
                              <div className="input-label">What else should we know about your event? </div>
                              <Field className="textarea" component="textarea" align="center" type="text" name="message" />
                              <div className="error"><ErrorMessage name="message" /></div>
                              <div className="error">{this.state.errorMessage}</div>
                            </div>
                          </div>
                        </div>
                      </Form>
                      )}
                    </Formik>
                  </Grid>
                  : 
                  <div className="eventsSelected">
                            <img
                              className="image"
                              src={require("./assets/images/logo.png")}
                              alt="FIRST-HOLD"
                            />

                            <p className="large-text">Inquiry sent</p>

                            <p className="description">
                              The venue manager will be notified and will be in
                              touch shortly
                            </p>

                            <p className="description">
                              You’ll get notifications through your
                              extension
                            </p>

                            <p className="description blue-text">
                              One last thing!
                            </p>

                            <p className="description">
                              How ready are you to book?
                            </p>

                            <p className="grey-text">Select one</p>

                            <div className="optionsDiv">
                              <div className="option">
                                <p className="option-text">
                                  Currently <br />
                                  Just Looking
                                </p>

                                <CustomisedRadio
                                  name="readyOption"
                                  value=" Currently Just Looking"
                                  onChange={this.handleRadioClick}
                                  class="radioButton"
                                  radioSelectedValue={
                                    this.state.radioSelectedValue
                                  }
                                />
                              </div>
                              <div className="option">
                                <p className="option-text">
                                  This is one of my top options!
                                </p>

                                <CustomisedRadio
                                  name="readyOption"
                                  value=" This is one of my top options!"
                                  onChange={this.handleRadioClick}
                                  class="radioButton"
                                  radioSelectedValue={
                                    this.state.radioSelectedValue
                                  }
                                />
                              </div>
                              <div className="option">
                                <p className="option-text">
                                  I’m probably going to book this space.
                                </p>

                                <CustomisedRadio
                                  name="readyOption"
                                  value=" I’m probably going to book this space."
                                  onChange={this.handleRadioClick}
                                  class="radioButton"
                                  radioSelectedValue={
                                    this.state.radioSelectedValue
                                  }
                                />
                              </div>
                            </div>

                            <div className="footerButtonDiv">
                            {this.state.loader === true ?

                             <img style={{marginTop:'-14px',paddingTop:'0px'}} src={require('../../shared/components/assets/images/loading.gif')} alt="Venue" /> 
                              :
                              <Button
                              variant="contained"
                              disabled={this.state.buttonDisabled}
                              size={"large"}
                              fullWidth={true}
                              onClick={this.eventSubmit}
                              className="grey-button"
                            >
                            Ok
                            </Button>}
                            </div>
                  <p class="powerby">Powered by FIRST-HOLD</p>
                </div>
                  )
                  :        
                  <Grid>
                    <Formik
                      initialValues={{ message: '' }}
                      validationSchema={LoginSchema}
                      onSubmit={this.handleSubmitMessage}
                    >
                      {({ isSubmitting }) => (
                        <Form className="formFields">

                          {localStorage.getItem('fhtoken') && parseInt(localStorage.getItem('type')) === 0 ?
                            <div>
                              <div className="form">
                                <div className="header">
                                  <div className="header-text">
                                    Contact Details
                          </div>
                                  <div className="header-button-div">
                                    <Button variant="outline-danger" class="white-button-big" type="submit">
                                      {this.props.sendData ? "Sending..." : "Send"}
                                    </Button>
                                  </div>
                                </div>
                              </div>

                              <div className="form-content">
                                <div className="field-div big">
                                  <div className="input-label">Message</div>
                                  {/* <textarea className="textarea" onChange={ this.handleChange }>

                    </textarea> */}
                                  <Field className="textarea" component="textarea" align="center" type="text" name="message" />
                                  <div className="error"><ErrorMessage name="message" /></div>
                                  <div className="error">{this.state.errorMessage}</div>

                                </div>

                              </div>

                            </div>
                            :
                            <div>
                              <div className="form">
                                <div className="header">
                                  <div className="header-text">
                                    Login
              </div>
                                </div>
                              </div>
                              <div className="form-content">

                                <center>Please Log-in as a Planner</center>
                                <div className="need_to_login_btn">
                                  <CustomizedButton onClickEvent={() => window.location.href = '/register'} label="Sign Up" type="submit" />
                                  <CustomizedButton onClickEvent={() => window.location.href = '/login'} label="Sign In" type="submit" />
                                </div>
                              </div>
                            </div>

                          }
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                }
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

contactPopup.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    //arrangements: state.data.master.arrangements
  };
};


const enhance = compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
       // getArrangements
      },
      dispatch
    )
  )
);
export default enhance(contactPopup);
